.slideBar {
  width: 100%;
  height: 350px;
  background-color: #333;
}

.toolsImg {
  width: 100%;
  height: auto;
}

.cardColumn {
  display: flex;
  flex-direction: column;
  min-height: 440px;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

.bottomCard {
  background: #f1f1f1;
  height: 90px;
  margin-top: auto;
  color: #333;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
}

.cardButton {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  overflow: hidden;
  color: #fff;
  display: flex;
  padding: 6px 20px;
  border-radius: 6px;
}

.cardButton:hover {
  background: #d1d1d1;
  box-shadow: 0px 2px 3px 3px rgba(0, 0, 0, 0.7);
  transform: translateY(-2px) scale(1.05);
}

.bottomCardIconContainer {
  margin: 0px 10px 0px 10px;
  min-width: 50px;
  text-align: center;
}

.bottomCardIcon {
  color: #333;
  font-size: 34px;
}

.bottomCardUrlText {
  color: #333;
  font-size: 14px;
  text-align: left;
}
