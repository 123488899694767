.logo {
  max-width: 180px !important;
  height: auto;
}

.logoReact {
  max-width: 70px !important;
  height: auto;
}

.logoReactTools {
  max-width: 80px !important;
  height: auto;
  margin-left: 10px;
}

.logoContainer {
  display: flex;
  align-items: center;
  padding: 21px 0 21px 0;
}

.footerCopyright {
  color: #fff;
}

.footerLink {
  color: #fff;
}
